var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-lg-5", attrs: { fluid: "" } },
    [
      _c("app-modal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isModalVisible,
            expression: "isModalVisible"
          }
        ],
        attrs: { modal: _vm.modal },
        on: {
          "submit-modal": _vm.submitModal,
          "text-changed": _vm.onDataChange,
          "time-changed": _vm.onDataChange
        }
      }),
      _c("app-header", {
        attrs: {
          title: _vm.title,
          breadcrumbs: _vm.breadcrumbs,
          actionButton: _vm.actionButton,
          showButton: true
        },
        on: { clicked: _vm.openCreateOfficeModal }
      }),
      _c(
        "v-card",
        { staticClass: "rounded-card", attrs: { flat: "", outlined: "" } },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.offices.items,
              options: _vm.options,
              "server-items-length": _vm.offices.count,
              loading: _vm.loading,
              flat: "",
              "no-data-text": _vm.$t("messages.emptyState", {
                entity: _vm.$tc("models.office.entity")
              }),
              "no-results-text": _vm.$t("messages.emptyState", {
                entity: _vm.$tc("models.office.entity")
              })
            },
            on: {
              "update:options": [
                function($event) {
                  _vm.options = $event
                },
                _vm.updateOptions
              ]
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.businessHoursStart",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm
                              .moment(item.businessHoursStart, "HH:mm")
                              .format("hh:mm A")
                          ) +
                          " - " +
                          _vm._s(
                            _vm
                              .moment(item.businessHoursEnd, "HH:mm")
                              .format("hh:mm A")
                          ) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.actions",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.editOffice(item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-pen")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.deleteOffice(item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-trash-can-outline")])],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }